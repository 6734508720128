<script setup lang="ts">
  const { isAuthenticated, user, token } = useAuth()
  const route = useRoute()
  const error = ref('')
  const config = useRuntimeConfig()

  onMounted(async () => {
    if (isAuthenticated.value) {
      try {
        const result = await $fetch('/api/discourse-sso', {
          method: 'post',
          headers: {
            'Authorization': `Bearer ${token.value}`,
          },
          body: {
            user: user.value,
            sso: route.query.sso,
            sig: route.query.sig,
          },
        })
        if (result.sig && result.sso) {
          window.location.href = `${config.public.discourseUrl}/session/sso_login?sso=${result.sso}&sig=${result.sig}`
        }
      } catch (err) {
        error.value = 'Někde se stala chyba, zkuste to prosím znovu.'
      }
    }
  })
</script>

<template>
  <div>
    <app-container
      v-if="!isAuthenticated"
      class="u-pt--600 u-pb--600"
      text>
      <app-grid :columns="2">
        <app-card>
          <login-form/>
        </app-card>
      </app-grid>
    </app-container>
    <div
      v-else
      class="u-d--flex u-justify--center u-pt--800 u-pb--600">
      <app-progress size="600"/>
    </div>
  </div>
</template>

